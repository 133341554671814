import { formatDateWithoutTime, today } from "@/lib/helper";
import axios from "axios";
import { GraphQLClient, gql, request } from "graphql-request";
import Cookie from "js-cookie";
import {
  default as useSWR,
  default as useSWRImmutable,
  default as useSWRInfinite,
} from "swr";
import markdownToHtml from "./markdownToHtml";

const qs = require("qs");

const fetcher = async (query, variables) => {
  const endpoint = `${process.env.NEXT_PUBLIC_API_URL}/graphql`;
  const bearer = Cookie.get("token");

  if (bearer) {
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${bearer}`,
      },
    });

    return graphQLClient.request(query, variables);
  } else {
    return request(endpoint, query, variables);
  }
};

const restFetcher = (url) => {
  const bearer = Cookie.get("token");
  let config = {};
  if (bearer) {
    config = {
      headers: {
        authorization: `Bearer ${bearer}`,
      },
    };
  }
  return axios.get(url, config).then((r) => r.data);
};

const restDeleteFetcher = (url) => {
  const bearer = Cookie.get("token");
  let config = {};
  if (bearer) {
    config = {
      headers: {
        authorization: `Bearer ${bearer}`,
      },
    };
  }
  return axios.delete(url, config).then((r) => r.data);
};

const pageFetcher = (url) =>
  fetch(url).then(async (res) => {
    let response = await res.json();
    response.content_html = await markdownToHtml(response.content);

    return response;
  });

export const getMe = async () => {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/users/me`);
};

export const getUser = async (userId) => {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/users/${userId}`);
};

export const usePing = async () => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/user-profiles/ping`,
  );

  return {
    data,
    isLoading,
    isValidating,
  };
};

export async function createBand(newBand) {
  const mutation = gql`
    mutation AddBand(
      $name: String!
      $last_updated_by: ID!
      $description: String
      $favourite_location: String
      $best_gig: String
      $credo: String
      $average_year_of_birth: Int
      $year_established: Int
      $zipcode: ID!
      $photo: ID!
      $techrider: ID
      $presskit: ID
      $genres: [ID]
      $genre_description: String
      $can_live: Boolean
      $allow_messages: Boolean
      $link_website: String
      $link_facebook: String
      $link_instagram: String
      $link_youtube: String
      $link_twitter: String
      $link_tiktok: String
      $link_soundcloud: String
      $link_bandcamp: String
      $link_spotify: String
      $link_applemusic: String
      $link_deezer: String
      $link_amazonmusic: String
    ) {
      createBand(
        input: {
          data: {
            name: $name
            description: $description
            favourite_location: $favourite_location
            best_gig: $best_gig
            credo: $credo
            average_year_of_birth: $average_year_of_birth
            year_established: $year_established
            zipcode: $zipcode
            photo: $photo
            techrider: $techrider
            presskit: $presskit
            genres: $genres
            genre_description: $genre_description
            can_live: $can_live
            allow_messages: $allow_messages
            link_website: $link_website
            link_facebook: $link_facebook
            link_instagram: $link_instagram
            link_youtube: $link_youtube
            link_twitter: $link_twitter
            link_soundcloud: $link_soundcloud
            link_bandcamp: $link_bandcamp
            link_spotify: $link_spotify
            link_applemusic: $link_applemusic
            link_deezer: $link_deezer
            link_amazonmusic: $link_amazonmusic
            link_tiktok: $link_tiktok
            last_updated_by: $last_updated_by
          }
        }
      ) {
        band {
          id
          slug
          name
          description
          credo
          year_established
          favourite_location
          can_live
          allow_messages
          verified
          updated_at
          best_gig
          genre_description
          average_year_of_birth
          link_website
          link_facebook
          link_instagram
          link_youtube
          link_twitter
          link_soundcloud
          link_bandcamp
          link_spotify
          link_applemusic
          link_deezer
          link_amazonmusic
          link_tiktok
          genres {
            id
            title
          }
          photo {
            id
            formats
          }
          zipcode {
            id
            label
            zipcode
          }
          members {
            id
            user_profile {
              id
              name
              gender
              email
            }
            pending
            musical_instruments {
              id
              name
            }
          }
          songs {
            id
            title
            soundcloud_id
            band {
              name
              slug
              photo {
                formats
              }
            }
          }
        }
      }
    }
  `;

  return await fetcher(mutation, newBand);
}

export async function updateBandSettings(id, data) {
  const mutation = gql`
    mutation UpdateBand(
      $can_live: Boolean
      $allow_messages: Boolean
    ) {
      updateBand(
        input: {
          where: { id: ${id} }
          data: {
            can_live: $can_live
            allow_messages: $allow_messages
          }
        }
      )
      {
        band {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updateBandScheduleExpire(id, data) {
  const mutation = gql`
    mutation UpdateBand(
      $expires_at: DateTime
    ) {
      updateBand(
        input: {
          where: { id: ${id} }
          data: {
            expires_at: $expires_at
          }
        }
      )
      {
        band {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updateBandGeneral(id, data) {
  const mutation = gql`
    mutation UpdateBand(
      $last_updated_by: ID!
      $description: String
      $favourite_location: String
      $best_gig: String
      $credo: String
      $average_year_of_birth: Int
      $year_established: Int
      $zipcode: ID!
      $photo: ID!
      $techrider: ID
      $presskit: ID
      $genres: [ID]
      $genre_description: String
      $link_website: String
      $link_facebook: String
      $link_instagram: String
      $link_youtube: String
      $link_twitter: String
      $link_tiktok: String
      $link_soundcloud: String
      $link_bandcamp: String
      $link_spotify: String
      $link_applemusic: String
      $link_deezer: String
      $link_amazonmusic: String
    ) {
      updateBand(
        input: {
          where: { id: ${id} }
          data: {
            description: $description
            favourite_location: $favourite_location
            best_gig: $best_gig
            credo: $credo
            average_year_of_birth: $average_year_of_birth
            year_established: $year_established
            zipcode: $zipcode
            photo: $photo
            techrider: $techrider
            presskit: $presskit
            genres: $genres
            genre_description: $genre_description
            link_website: $link_website
            link_facebook: $link_facebook
            link_instagram: $link_instagram
            link_youtube: $link_youtube
            link_twitter: $link_twitter
            link_soundcloud: $link_soundcloud
            link_bandcamp: $link_bandcamp
            link_spotify: $link_spotify
            link_applemusic: $link_applemusic
            link_deezer: $link_deezer
            link_amazonmusic: $link_amazonmusic
            link_tiktok: $link_tiktok
            last_updated_by: $last_updated_by
          }
        }
      )
      {
        band {
          id
        }
      }
    }`;

  return await fetcher(mutation, data);
}

export async function verifyBand(id, data) {
  const mutation = gql`
    mutation UpdateBand(
      $verified: Boolean
    ) {
      updateBand(
        input: {
          where: { id: ${id} }
          data: {
            verified: $verified
          }
        }
      )
      {
        band {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function createSong(newSong) {
  const mutation = gql`
    mutation AddSong(
      $title: String!
      $band: ID!
      $genre: ID!
      $language: String!
      $soundcloud_link: String
      $hide_in_lists: Boolean
    ) {
      createSong(
        input: {
          data: {
            title: $title
            band: $band
            genre: $genre
            language: $language
            soundcloud_link: $soundcloud_link
            hide_in_lists: $hide_in_lists
          }
        }
      ) {
        song {
          id
          title
          genre {
            id
            title
          }
          band {
            id
          }
          language
          soundcloud_link
          hide_in_lists
        }
      }
    }
  `;

  return await fetcher(mutation, newSong);
}

export async function updateSongVisibiltyState(id, data) {
  const mutation = gql`
    mutation UpdateSong(
      $hide_in_lists: Boolean
    ) {
      updateSong(
        input: {
          where: { id: ${id} }
          data: {
            hide_in_lists: $hide_in_lists
          }
        }
      ) {
        song {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updateSong(id, data) {
  const mutation = gql`
    mutation UpdateSong(
      $title: String!
      $genre: ID!
      $language: String!
      $soundcloud_link: String
      $hide_in_lists: Boolean
    ) {
      updateSong(
        input: {
          where: { id: ${id} }
          data: {
            title: $title
            genre: $genre
            language: $language
            soundcloud_link: $soundcloud_link
            hide_in_lists: $hide_in_lists
          }
        }
      ) {
        song {
          id
          title
          genre {
            id
            title
          }
          band {
            id
          }
          language
          soundcloud_link
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function deleteSong(id) {
  const mutation = gql`
    mutation {
      deleteSong(
        input: { where: { id: ${id} } }
      ) {
        song {
          id
        }
      }
    }`;

  return await fetcher(mutation);
}

export async function createUserProfile(newProfile) {
  const mutation = gql`
    mutation AddUserProfile($name: String, $gender: String, $email: String) {
      createUserProfile(
        input: { data: { name: $name, gender: $gender, email: $email } }
      ) {
        userProfile {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newProfile);
}

export async function updateBandMemberInstruments(id, data) {
  /*const mutation = gql`
    mutation UpdateBandMember(
      $musical_instruments: [ID]
    ) {
      updateBandMember(
        input: {
          where: { id: ${id} }
          data: {
            musical_instruments: $musical_instruments
          }
        }
      )
      {
        bandMember {
          id
        }
      }
    }
  `

  return await fetcher(mutation, data);

  const result = await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}/band-members/${id}`,
      data,
      {
        headers: {
          authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
  );

  return result.data;*/

  const result = await axios.put(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/band-members/${id}`,
    data,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export async function updateBandMember(id, data) {
  const mutation = gql`
    mutation UpdateBandMember(
      $band: ID!
      $profile: ID!
      $instruments: [ID]
    ) {
      updateBandMember(
        input: {
          where: { id: ${id} }
          data: {
            band: $band
            user_profile: $profile
            musical_instruments: $instruments
          }
        }
      )
      {
        bandMember {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);

  const result = await axios.put(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/band-members/${id}`,
    data,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export async function createBandMember(newMember) {
  /*const mutation = gql`
    mutation AddBandMember(
      $band: ID!
      $profile: ID!
      $instruments: [ID]
    ) {
      createBandMember(
        input: {
          data: {
            band: $band
            user_profile: $profile
            musical_instruments: $instruments
          }
        }
      ) {
        bandMember {
          id
        }
      }
    }
  `

  return await fetcher(mutation, newMember);*/

  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/band-members`,
    newMember,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export async function deleteBandMember(id) {
  const mutation = gql`
    mutation {
      deleteBandMember(
        input: { where: { id: ${id} } }
      ) {
        bandMember {
          id
        }
      }
    }`;

  return await fetcher(mutation);
}

export async function createBandFriendship(newFriendship) {
  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/band-friendships`,
    newFriendship,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export async function updateBandFriendship(id, data) {
  const mutation = gql`
    mutation UpdateBandFriendship(
      $confirmed: DateTime
    ) {
      updateBandFriendship(
        input: {
          where: { id: ${id} }
          data: {
            confirmed: $confirmed
          }
        }
      )
      {
        bandFriendship {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function deleteBandFriendship(id) {
  const mutation = gql`
    mutation {
      deleteBandFriendship(
        input: { where: { id: ${id} } }
      ) {
        bandFriendship {
          id
        }
      }
    }`;

  return await fetcher(mutation);
}

export function useCreatedBandFriendships(band) {
  const query = `
    {
      bandFriendships
      (
        where: {
          band: ${band?.id}
          followed_band_null: false
        }
      ) {
        id
        confirmed
        band {
          id
          slug
          name
          photo {
            formats
          }
          genres {
            title
          }
          genre_description
        }
        followed_band {
          id
          slug
          name
          photo {
            formats
          }
          genres {
            title
          }
          genre_description
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(band?.id ? query : null, fetcher);

  return {
    bandFriendships: data?.bandFriendships || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useReceivedBandFriendships(band) {
  const query = `
    {
      bandFriendships
      (
        where: {
          followed_band: ${band?.id}
          band_null: false
        }
      ) {
        id
        confirmed
        band {
          id
          slug
          name
          photo {
            formats
          }
          genres {
            title
          }
          genre_description
        }
        followed_band {
          id
          slug
          name
          photo {
            formats
          }
          genres {
            title
          }
          genre_description
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(band?.id ? query : null, fetcher);

  return {
    bandFriendships: data?.bandFriendships ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useArticles(category = undefined, limit = -1) {
  const categoryFilter = !category
    ? ""
    : `article_category: { id: ${category.id ?? category} }`;

  const query = `
  {
    articles(
      where: {
        ${categoryFilter}
      }
      sort: "date_display:desc"
      limit: ${limit}
    ) {
      id
      slug
      title
      teaser
      image {
        formats
      }
      date_display
      published_at
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    articles: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function reportBand(bandId) {
  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/bands/report`,
    { id: bandId },
  );
}

export function useBands() {
  const query = `
  {
    bands
    {
      id
      slug
      name
      zipcode {
        label
      }
      genres {
        title
      }
      photo {
        formats
      }
      last_updated_by {
        profile {
          name
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    bands: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useBandsById(ids) {
  const query = `
  {
      bands(
        where: {
          id_in: [${ids}]
        }
      )
      {
        id
        slug
        name
      }
  }
  `;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    query,
    fetcher,
  );

  return {
    bands: data?.bands,
    isLoading,
    isValidating,
    isError: error,
  };
}

export function useBandIndex(index, count) {
  const query = `
  {
    bands(
      where: {
        verified: true
      },
      start: ${index},
      limit: ${count},
      sort: "name:asc") {
      id
      slug
      name
      allow_messages
      verified
      zipcode {
        label
      }
      photo {
          formats
      }
      genres {
        title
      }
      songs {
        id
        title
        soundcloud_id
        band {
          id
          slug
          name
          photo {
            formats
          }
        }
      }
    }
  }`;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    query,
    fetcher,
  );

  return {
    bands: data?.bands,
    isLoading,
    isValidating,
    isError: error,
  };
}

export const getBand = async (bandId) => {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/bands/${bandId}`);
};

export const getBandCountByName = async (name) => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/bands/count?name_contains=${name}`,
  );
};

export const useVerifiedBandsCount = () => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/bands/count?verified=1`,
  );

  return {
    count: data ?? 0,
    isLoading,
    isValidating,
  };
};

export function useBandsContactable() {
  const query = `
  {
    bands(
      where: {
        allow_messages: true
        verified: true
      }
    )
    {
      id
      slug
      name
      photo {
        formats
      }
      members {
        user_profile {
         id
         name
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    bands: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useSearchBands(filterConfig, start = 0, limit = 24) {
  const queryFilter = !filterConfig.query ? "" : `&query=${filterConfig.query}`;
  const regionFilter = !filterConfig.region
    ? ""
    : `&regionId=${filterConfig.region?.id ?? filterConfig.region}`;
  const genreFilter = !filterConfig.genre
    ? ""
    : `&genreId=${filterConfig.genre?.id ?? filterConfig.genre}`;
  const languageFilter = !filterConfig.language
    ? ""
    : `&language=${filterConfig.language}`;
  const surroundFilter =
    !filterConfig.radius || !filterConfig.zipcode
      ? ""
      : `&zipcode=${filterConfig.zipcode.zipcode}&radius=${filterConfig.radius}`;
  const genderFilter =
    !filterConfig.gender || filterConfig.gender === "not_specified"
      ? ""
      : `&gender=${filterConfig.gender}`;
  const minMemberFilter = !filterConfig.members_min
    ? ""
    : `&members_min=${filterConfig.members_min}`;
  const maxMemberFilter = !filterConfig.members_max
    ? ""
    : `&members_max=${filterConfig.members_max}`;
  const minAgeFilter = !filterConfig.age_min
    ? ""
    : `&age_min=${filterConfig.age_min}`;
  const maxAgeFilter = !filterConfig.age_max
    ? ""
    : `&age_max=${filterConfig.age_max}`;

  const sort = !filterConfig._sort ? "" : `&_sort=${filterConfig._sort}`;

  const allFilters = `${queryFilter}${regionFilter}${genreFilter}${languageFilter}${genderFilter}${minMemberFilter}${maxMemberFilter}${minAgeFilter}${maxAgeFilter}${surroundFilter}${sort}`;

  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(
    `/api/bands?_start=${start}&_limit=${limit}&${allFilters}`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    bands: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useSearchSongs(filterConfig, start, limit) {
  const queryFilter = !filterConfig.query ? "" : `&query=${filterConfig.query}`;
  const regionFilter = !filterConfig.region
    ? ""
    : `&regionId=${filterConfig.region?.id ?? filterConfig.region}`;
  const genreFilter = !filterConfig.genre
    ? ""
    : `&genreId=${filterConfig.genre?.id ?? filterConfig.genre}`;
  const languageFilter = !filterConfig.language
    ? ""
    : `&language=${filterConfig.language}`;
  const surroundFilter =
    !filterConfig.radius || !filterConfig.zipcode
      ? ""
      : `&zipcode=${filterConfig.zipcode.zipcode}&radius=${filterConfig.radius}`;
  const genderFilter =
    !filterConfig.gender || filterConfig.gender === "not_specified"
      ? ""
      : `&gender=${filterConfig.gender}`;
  const minMemberFilter = !filterConfig.members_min
    ? ""
    : `&members_min=${filterConfig.members_min}`;
  const maxMemberFilter = !filterConfig.members_max
    ? ""
    : `&members_max=${filterConfig.members_max}`;
  const minAgeFilter = !filterConfig.age_min
    ? ""
    : `&age_min=${filterConfig.age_min}`;
  const maxAgeFilter = !filterConfig.age_max
    ? ""
    : `&age_max=${filterConfig.age_max}`;

  const sort = !filterConfig._sort ? "" : `&_sort=${filterConfig._sort}`;

  const allFilters = `${queryFilter}${regionFilter}${genreFilter}${languageFilter}${genderFilter}${minMemberFilter}${maxMemberFilter}${minAgeFilter}${maxAgeFilter}${surroundFilter}${sort}`;

  const { data, error, isLoading, isValidating, mutate } = useSWRInfinite(
    `/api/songs?_start=${start}&_limit=${limit}&${allFilters}`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    songs: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useSongsForBand(bandId) {
  const query = `
    {
      songs
      (
        where: {
          band: ${bandId}
        }
      ) {
        id
        title
        language
        soundcloud_id
        soundcloud_link
        hide_in_lists
        genre {
          id
          title
        }
        band {
          id
          name
          slug
          allow_messages
          verified
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(bandId ? query : null, fetcher);

  return {
    songs: data?.songs,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useLikedSong(songId, userId) {
  const query = `
    {
      songLikes
      (
        where: {
          song: ${songId}
          user: ${userId}
        }
        limit: 1
      ) {
        id
      }
    }
  `;

  const { data, error, mutate } = useSWR(
    songId && userId ? query : null,
    fetcher,
  );

  return {
    likedSong: data?.songLikes[0] || null,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export async function createLikedSong(newLike) {
  const mutation = gql`
    mutation AddSongLike($song: ID!, $user: ID) {
      createSongLike(input: { data: { song: $song, user: $user } }) {
        songLike {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newLike);
}

export async function deleteLikedSong(id) {
  const mutation = gql`
    mutation {
      deleteSongLike(
        input: { where: { id: ${id} } }
      ) {
        songLike {
          id
        }
      }
    }`;

  return await fetcher(mutation);
}

export async function createPlayedSong(newPlay) {
  const mutation = gql`
    mutation AddSongPlay($song: ID!, $user: ID) {
      createSongPlay(input: { data: { song: $song, user: $user } }) {
        songPlay {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newPlay);
}

export function generateSongOfTheWeek() {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/song-of-the-week/generate`,
  );
}

export function useSongOfTheWeek() {
  const query = `
  {
    songOfTheWeek {
      song {
        id
        title
        soundcloud_id
        band {
          name
          slug
          photo {
            formats
          }
          allow_messages
          verified
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    songOfTheWeek: data?.songOfTheWeek,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function getSongOfTheWeek() {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/song-of-the-week`);
}

export function usePlaylistOfTheWeek() {
  const query = `
  {
    playlistOfTheWeek {
      playlist {
        title
        image {
          formats
        }
        songs {
          id
          title
          soundcloud_id
          band {
            name
            slug
            photo {
              formats
            }
            allow_messages
            verified
          }
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    playlistOfTheWeek: data?.playlistOfTheWeek,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getPlaylists() {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/playlists?songs_null=false`,
  );
}

export async function reportPlaylist(playlistId) {
  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/playlists/report`,
    { id: playlistId },
  );
}

export function usePlaylist(slug) {
  const query = `
  {

      playlists(
        where: {
          slug: "${slug}"
          songs_null: false
          songs: { band_null: false }
        }
        limit: 1
      ) {
        id
        slug
        title
        name
        image {
          formats
        }
        type
        songs {
          id
          title
          soundcloud_id
          genre {
            title
          }
          band {
            name
            slug
            photo {
              formats
            }
            zipcode {
              label
            }
            allow_messages
            verified
          }
        }
      }

  }`;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    playlist: data?.playlists[0],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useGenres() {
  const query = `
  {
    genres(
      where: {
        show_on_page: true
      }
      sort: "title:asc"
    ) {
      id
      title
    }
  }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    genres: data?.genres || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useMusicalInstruments() {
  const query = `
  {
    musicalInstruments(
      where: {
        show_on_page: true
      }
      sort: "name:asc"
    ) {
      id
      name
    }
  }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    instruments: data?.musicalInstruments || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useRegion(id) {
  const query = `
  {
    regions(
      limit: 1,
      sort: "name:asc",
      where: {
        id: ${id}
      }) {
      id
      name
      father {
        id, name
      }
      zipcodes {
        id
      }
    }
  }
  `;

  const { data, error, mutate } = useSWR(id ? query : null, fetcher);

  return {
    region: data?.regions[0] ?? null,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useRegions(father = null) {
  const fatherQuery = !father
    ? `father_null: true`
    : `father: { id: ${father.id ?? father}}`;
  const query = `
  {
    regions(
      limit: 1000,
      sort: "name:asc",
      where: {
        ${fatherQuery}
      }) {
      id
      name
      father {
        id, name
      }
      zipcodes {
        id
      }
    }
  }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    regions: data?.regions || [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useLandkreise() {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `/api/regions?regiontype=landkreis`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    regions: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useAllRegions() {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `/api/regions?_limit=1000&_sort=name`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    regions: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useZipcodes() {
  const query = `
  {
    zipcodes(sort: "zipcode:asc")
    {
      id
      zipcode
      label
      lat
      lng
    }
  }`;

  const { data, error } = useSWR(query, fetcher, { revalidateOnFocus: false });

  return {
    zipcodes: data?.zipcodes,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useLocationCategories(hideCategories = null) {
  let hiddenCategoryFilter = "";

  if (hideCategories) {
    hiddenCategoryFilter = `where: {id_nin: [${hideCategories}]}`;
  }
  const query = `
    {
      locationCategories(
        sort: "name:asc"
        ${hiddenCategoryFilter}
      ) {
        id
        name
        color
        icon
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    categories: data?.locationCategories,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useLocationSubcategories() {
  const query = `
    {
      locationSubcategories {
        id
        name
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    locationSubcategories: data?.locationSubcategories,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

/*
export function useLocationCategories() {
  const query = `
    {
      locationCategories {
        id
        name
        color
        icon
      }
    }
  `;

  //const { data, error, mutate } = useSWR(query, fetcher);

  return {
    categories: [], //data?.locationCategories,
    isLoading: true, //!error && !data,
    isError: false, //error,
    mutate: null, //mutate
  };
}
*/

export function useLocation(id) {
  const query = `
  {
    location(id: ${id})
    {
      id
      name
      address
      description
      contactperson
      phone
      email
      category {
        name
        icon
      }
      TeamMember {
        ... on ComponentPageContentTeamMember {
          name
          role
          photo {
            formats
          }
        }
      }
    }
  }`;

  const { data, error, mutate } = useSWR(id ? query : null, fetcher);

  return {
    location: data?.location,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function useLocationsFromProxy() {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `/proxy/locations`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    locations: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useLocationsForMap(
  categoryFilter = null,
  capacity = null,
  regionFilter = null,
  surroundingFilter = null,
) {
  return useLocationsFromApi(
    `${process.env.NEXT_PUBLIC_API_URL}/locations?display_on_map=1`,
    categoryFilter,
    capacity,
    regionFilter,
    surroundingFilter,
  );
}

export function useLocations(
  categoryFilter = null,
  capacity = null,
  regionFilter = null,
  surroundingFilter = null,
) {
  return useLocationsFromApi(
    `${process.env.NEXT_PUBLIC_API_URL}/locations`,
    categoryFilter,
    capacity,
    regionFilter,
    surroundingFilter,
  );
}

function useLocationsFromApi(
  apiUrl,
  categoryFilter = null,
  capacity = null,
  regionFilter = null,
  surroundingFilter = null,
) {
  const restFilterCategory =
    categoryFilter == null
      ? ""
      : qs.stringify({ _where: { category: categoryFilter } });
  const restFilterRegion =
    regionFilter == null ? "" : `&regionId=${regionFilter.id}`;
  const restFilterZipcode =
    !surroundingFilter || surroundingFilter.zipcode == null
      ? ""
      : `&zipcodeId=${surroundingFilter.zipcode.id}`;
  const restFilterRadius =
    !surroundingFilter || surroundingFilter.radius == null
      ? ""
      : `&radius=${surroundingFilter.radius}`;

  let url = `${apiUrl}${
    apiUrl.indexOf("?") > -1 ? "&" : "?"
  }${restFilterCategory}${restFilterRegion}${restFilterZipcode}${restFilterRadius}`;
  while (url.indexOf("&&") > -1) {
    url = url.replaceAll("&&", "&");
  }

  url = url.replaceAll("?&", "?");

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    url,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    locations: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export async function createLocation(location) {
  const mutation = gql`
    mutation AddLocation(
      $name: String
      $category: ID!
      $location_subcategory: ID
      $zipcode: ID
      $genres: [ID]
      $musical_instruments: [ID]
      $studiengangs: [ID]
      $university_degrees: [ID]
      $address: String
      $contactperson: String
      $description: String
      $base: Boolean
      $display_on_map: Boolean
      $website: String
      $live: Boolean
      $dj: Boolean
      $rooms: Int
      $recording: Boolean
      $mixing: Boolean
      $mastering: Boolean
      $capacity: Int
      $phone: String
      $email: String
      $link_facebook: String
      $link_twitter: String
      $link_youtube: String
      $link_bandcamp: String
      $link_spotify: String
      $link_instagram: String
      $link_tiktok: String
      $year_established: Int
      $visitors: Int
      $duration: Int
      $num_creators: Int
      $num_publications: Int
      $medium_digital: Boolean
      $medium_physical: Boolean
      $focus: String
      $sponsor_public: Boolean
      $sponsor_private: Boolean
      $creator_name: String
      $creator_email: String
      $creator_consent_datashare: DateTime
    ) {
      createLocation(
        input: {
          data: {
            name: $name
            zipcode: $zipcode
            category: $category
            location_subcategory: $location_subcategory
            genres: $genres
            musical_instruments: $musical_instruments
            studiengangs: $studiengangs
            university_degrees: $university_degrees
            address: $address
            contactperson: $contactperson
            description: $description
            base: $base
            display_on_map: $display_on_map
            website: $website
            live: $live
            dj: $dj
            rooms: $rooms
            recording: $recording
            mixing: $mixing
            mastering: $mastering
            capacity: $capacity
            phone: $phone
            email: $email
            link_facebook: $link_facebook
            link_twitter: $link_twitter
            link_youtube: $link_youtube
            link_bandcamp: $link_bandcamp
            link_spotify: $link_spotify
            link_instagram: $link_instagram
            link_tiktok: $link_tiktok
            year_established: $year_established
            visitors: $visitors
            duration: $duration
            num_creators: $num_creators
            num_publications: $num_publications
            medium_digital: $medium_digital
            medium_physical: $medium_physical
            focus: $focus
            sponsor_public: $sponsor_public
            sponsor_private: $sponsor_private
            creator_name: $creator_name
            creator_email: $creator_email
            creator_consent_datashare: $creator_consent_datashare
          }
        }
      ) {
        location {
          id
          name
        }
      }
    }
  `;

  return await fetcher(mutation, location);
}

export async function createPopboardBand(band) {
  const mutation = gql`
    mutation AddPopboardBand(
      $name: String!
      $zipcode: ID
      $genre: String
      $website: String
      $display_on_map: Boolean
      $creator_name: String
      $creator_email: String
      $creator_consent_datashare: DateTime
      $type: ENUM_POPBOARDBAND_TYPE
      $source: ENUM_POPBOARDBAND_SOURCE
    ) {
      createPopboardBand(
        input: {
          data: {
            name: $name
            zipcode: $zipcode
            genre: $genre
            website: $website
            display_on_map: $display_on_map
            creator_name: $creator_name
            creator_email: $creator_email
            creator_consent_datashare: $creator_consent_datashare
            type: $type
            source: $source
          }
        }
      ) {
        popboardBand {
          id
          name
        }
      }
    }
  `;

  return await fetcher(mutation, band);
}

export async function createVenue(newVenue) {
  const result = await axios.post(
    `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}/locations`,
    newVenue,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export function useOldestEvent() {
  const query = `
  {
    events(
      sort: "date_start:asc"
      limit: 1
    )
    {
      id
      date_start
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    event: data?.events[0],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useNewestEvent() {
  const query = `
  {
    events(
      sort: "date_start:desc"
      limit: 1
    )
    {
      id
      date_start
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    event: data?.events[0],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useEvents(
  startDate,
  endDate,
  genreId,
  regionId,
  zipcodeId,
  radius,
) {
  let url = `${
    process.env.NEXT_PUBLIC_API_URL
  }/events/search?_sort=date_start:asc&date_start=${formatDateWithoutTime(
    startDate,
  )}&date_end=${formatDateWithoutTime(endDate)}`;
  if (genreId) {
    url += `&genreId=${genreId}`;
  }
  if (regionId) {
    url += `&regionId=${regionId}`;
  }
  if (zipcodeId && radius) {
    url += `&zipcodeId=${zipcodeId}&radius=${radius.value ?? radius}`;
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    url,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    events: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useEventsForDashboard(band) {
  let genres = [];
  for (const g of band.genres) {
    genres = [...genres, g.id];
  }
  const query = `
  {
    events (
      where: { targetgroup_genres_in: [${genres.join(",")}]}
      limit: 25
    )
    {
      id
      name
      date_start
      time_start
      category {
        name
      }
      location {
        name
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    events: data?.events,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function createEvent(newEvent) {
  const mutation = gql`
    mutation AddBand(
      $bands: [ID]!
      $name: String!
      $date_start: DateTime!
      $date_end: DateTime
      $time_start: DateTime
      $zipcode: ID
      $location: ID
      $link: String
      $usergenerated: Boolean
    ) {
      createEvent(
        input: {
          data: {
            bands: $bands
            name: $name
            date_start: $date_start
            date_end: $date_end
            time_start: $time_start
            zipcode: $zipcode
            location: $location
            link: $link
            usergenerated: $usergenerated
          }
        }
      ) {
        event {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newEvent);
}
export async function updateEvent(id, data) {
  const mutation = gql`
    mutation UpdateEvent(
      $bands: [ID]!
      $name: String!
      $date_start: DateTime!
      $date_end: DateTime
      $time_start: DateTime
      $zipcode: ID
      $location: ID
      $link: String
      $usergenerated: Boolean
    ) {
      updateEvent(
        input: {
          where: { id: ${id} }
          data: {
            bands: $bands
            name: $name
            date_start: $date_start
            date_end: $date_end
            time_start: $time_start
            zipcode: $zipcode
            location: $location
            link: $link
            usergenerated: $usergenerated
          }
        }
      ) {
        event {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function deleteEvent(id) {
  const mutation = gql`
    mutation {
      deleteEvent(
        input: { where: { id: ${id} } }
      ) {
        event {
          id
        }
      }
    }`;

  return await fetcher(mutation);
}

export function useEventsForBand(bandId) {
  const query = `
  {
    events (
      where: {
        bands: {
          id: ${bandId}
        }
      }
    ) {
      id
      name
      date_start
      time_start
      date_end
      link
      bands {
        id
      }
      location {
        id
        name
        zipcode {
          label
        }
      }
      zipcode {
        id
        zipcode
        label
      }
    }
  }`;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    events: data?.events,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function useEvent(id) {
  const query = `
  {
    event(id: ${id})
    {
      id
      name
      date_start
      time_start
      link
      bands {
        name
        slug
        photo {
          formats
        }
      }
      location {
        name
        zipcode {
          label
        }
      }
      zipcode {
        label
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    event: data?.event,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useWorkshopCategories() {
  const query = `
  {
    workshopCategories
    {
      id
      name
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    categories: data?.workshopCategories,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useWorkshopOrganizers() {
  const query = `
  {
    workshopOrganizers
    {
      id
      name
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    organizers: data?.workshopOrganizers,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useWorkshops(filterConfig = {}) {
  const categoryFilter = !filterConfig.category
    ? qs.stringify({ _where: { category_null: false } })
    : qs.stringify({ _where: { category: filterConfig.category } });
  const organizerFilter = !filterConfig.workshop_organizer
    ? ""
    : qs.stringify({
        _where: { workshop_organizer: filterConfig.workshop_organizer },
      });
  const onlineFilter =
    !filterConfig.online == null
      ? ""
      : qs.stringify({ _where: { online: filterConfig.online } });
  const regionFilter = !filterConfig.region
    ? ""
    : `regionId=${filterConfig.region.id}`;

  let allFilters =
    `?_sort=start_date&start_date_gte=${today()}&${categoryFilter}&${organizerFilter}&${onlineFilter}&${regionFilter}`.replaceAll(
      "&&",
      "&",
    ); //?${qs.stringify({_where: { start_date: { gte: "2009-01-01T12:00:00+01:00"}}})}`;

  if (allFilters.substring(0, 1) === "&") {
    allFilters = allFilters.substring(1);
  }

  if (allFilters === "&") {
    allFilters = "";
  }

  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(
    `${process.env.NEXT_PUBLIC_API_URL}/workshops${allFilters}`,
    restFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return {
    workshops: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useWorkshop(id) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    id != null ? `${process.env.NEXT_PUBLIC_API_URL}/workshops/${id}` : null,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    workshop: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useDevelopmentPrograms(filterConfig) {
  const restFilterCategory =
    !filterConfig || filterConfig?.category === ""
      ? ""
      : qs.stringify({ _where: { category: filterConfig.category } });
  const restFundingFilter =
    !filterConfig || filterConfig?.funding === ""
      ? ""
      : qs.stringify({ _where: { funding: filterConfig.funding } });
  const restZipcodeFilter =
    !filterConfig || filterConfig?.zipcode === ""
      ? ""
      : qs.stringify({
          _where: { "region.zipcodes.id_in": [filterConfig.zipcode] },
        });
  const restRegionFilter =
    !filterConfig ||
    filterConfig?.region === undefined ||
    filterConfig?.region === null
      ? ""
      : `regionId=${filterConfig.region}`;

  const deadlineFilter = qs.stringify({
    _where: { _or: [{ deadline_null: true }, { deadline_gte: today() }] },
  });

  const sort = `_sort=${filterConfig._sort ?? "created_at:desc"}`;

  const params = `?${sort}&${deadlineFilter}&${restFilterCategory}&${restFundingFilter}&${restRegionFilter}&${restZipcodeFilter}`;

  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(
    `${process.env.NEXT_PUBLIC_API_URL}/development-programs${params}`,
    restFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  return {
    developmentPrograms: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useDevelopmentProgram(id) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    id != null
      ? `${process.env.NEXT_PUBLIC_API_URL}/development-programs/${id}`
      : null,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    program: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useDevelopmentProgramFundings() {
  const query = `
  {
    developmentPrograms(

      where: {
        funding_null: false
      }
    )
    {
      funding
    }
  }`;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    fundings: data?.developmentPrograms,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function useDevelopmentProgramCategories() {
  const query = `
  {
    developmentProgramCategories
    {
      id
      name
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    categories: data?.developmentProgramCategories,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useBandById(id) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    id != null ? `${process.env.NEXT_PUBLIC_API_URL}/bands/${id}` : null,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    band: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useBand(slug) {
  const query = `
  {
    bands(
      where: {
        slug: "${slug}"
      }
      limit: 1
    ){
      id
      slug
      name
      description
      credo
      year_established
      favourite_location
      can_live
      allow_messages
      verified
      updated_at
      best_gig
      genre_description
      average_year_of_birth
      link_website
      link_facebook
      link_instagram
      link_youtube
      link_twitter
      link_soundcloud
      link_bandcamp
      link_spotify
      link_applemusic
      link_deezer
      link_amazonmusic
      link_tiktok
      genres {
        id
        title
      }
      photo {
        id
        formats
      }
      zipcode {
        id
        label
        zipcode
      }
      members {
        id
        user_profile {
          id
          name
          gender
          email
          user {
            id
          }
        }
        pending
        musical_instruments {
          id
          name
        }
      }
      events {
        id
        name
        date_start
        date_end
        time_start
        location {
          name
          zipcode {
            label
          }
        }
        zipcode {
          label
        }
        link
      }
      last_updated_by {
        profile {
          name
        }
      }
      presskit {
        id
        url
      }
      location {
        id
        name
        address
        contactperson
        phone
        email
        website
      }
      techrider {
        id
        url
      }
      songs {
        id
        title
        soundcloud_id
        band {
          id
          name
          slug
          photo {
            formats
          }
        }
      }
    }
  }`;

  const { data, error, isLoading, mutate } = useSWR(
    slug ? query : null,
    fetcher,
  );

  return {
    band: data?.bands[0],
    isLoading,
    isError: error,
    mutate: mutate,
  };
}

export const getBandById = async (id) => {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/bands/${id}`);
};

export const getBandBySlug = async (slug) => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/bands?expires_at_null=true&slug=${slug}`,
  );
};

export const getPublicBands = async () => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/bands?expires_at_null=true`,
  );
};

export function useBandInvitations(userId) {
  const query = `
  {
    bandMembers(
      where: {
        user_profile: ${userId}
        pending: true
      }
    ) {
      id
      band {
        name
        slug
      }
      user_profile {
        id
        name
      }
    }
  }`;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    members: data?.bandMembers,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export async function updateBandInvitation(id, data) {
  const mutation = gql`
    mutation UpdateBandMember(
      $pending: Boolean
    ) {
      updateBandMember(
        input: {
          where: { id: ${id} }
          data: {
            pending: $pending
          }
        }
      )
      {
        bandMember {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export function useBandMembers(bandId) {
  const query = `
  {
    bandMembers(
      where: {
        band: ${bandId}
      }
    ) {
      id
      musical_instruments {
        id
        name
      }
      user_profile {
        id
        name
        gender
        email
        user {
          id
          email
        }
      }
    }
  }`;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    members: data?.bandMembers,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useUserProfiles() {
  const query = `
  {
    userProfiles(
      where: {
          name_null: false
          user_null: false
        }
    )
    {
      id
      name
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    profiles: data?.userProfiles,
    isLoading: !error && !data,
    isError: error,
  };
}

export const getUserProfile = async (userprofileId) => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/user-profiles/${userprofileId}`,
  );
};

export function useBandsForUserProfile(profileId) {
  const query = `
  {
    bandMembers(
      where: {
        user_profile: {
          id: ${profileId}
        }
        band_null: false
        band: {
          expires_at_null: true
        }
        pending: false
      }
    ) {
      band {
        id
        slug
        name
        verified
        zipcode {
          zipcode
        }
        photo {
          formats
        }
        members {
          id
          user_profile {
            id
          }
        }
        genres {
          title
        }
        genre_description
      }
    }
  }`;

  const { data, error } = useSWR(profileId ? query : null, fetcher);

  return {
    memberships: data?.bandMembers,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useBoardPosts(
  regionId = null,
  profile = null,
  band = null,
  expires = null,
) {
  const profileFilter = !profile
    ? ""
    : qs.stringify({ _where: { user_profile: profile.id } });
  const bandFilter = !band ? "" : qs.stringify({ _where: { band: band.id } });
  const regionFilter = !regionId ? "" : `regionId=${regionId}`;
  const expireFilter = !expires
    ? ""
    : qs.stringify({ _where: { expires_at_lte: expires } });

  let allFilters =
    `&${regionFilter}&${bandFilter}&${profileFilter}&${expireFilter}&_sort=created_at:desc`.replaceAll(
      "&&",
      "&",
    );

  if (allFilters.substring(0, 1) === "&") {
    allFilters = allFilters.substring(1);
  }

  if (allFilters === "&") {
    allFilters = "";
  }

  if (allFilters.length > 1) {
    allFilters = `?${allFilters}`;
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/bulletin-board-posts${allFilters}`,
    restFetcher,
  );

  return {
    posts: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useBoardPost(postId) {
  const query = `
  {
    bulletinBoardPost(id: ${postId})
    {
      id
      title
      user_profile {
        id
        name
      }
      band {
        id
        name
      }
      category {
        id
        name
        color
      }
      created_at
      expires_at
      content
      cover_image {
        id
        name
        url
        formats
        mime
      }
      attachments {
        id
        name
        url
        formats
        mime
      }
      zipcode {
        id
        zipcode
        label
      }
    }
  }`;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    postId != null ? query : null,
    fetcher,
  );

  return {
    post: data?.bulletinBoardPost,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export async function deleteBoardEntry(id) {
  const mutation = gql`
    mutation {
      deleteBulletinBoardPost(
        input: { where: { id: ${id} } }
      ) {
        bulletinBoardPost {
          id
        }
      }
    }`;

  return await fetcher(mutation);
}

export const createBoardEntry = async (newEntry) => {
  const mutation = gql`
    mutation AddBulletinBoardPost(
      $title: String!
      $zipcode: ID!
      $category: ID!
      $band: ID
      $profile: ID!
      $content: String!
      $cover_image: ID
      $attachments: [ID]
    ) {
      createBulletinBoardPost(
        input: {
          data: {
            title: $title
            zipcode: $zipcode
            category: $category
            band: $band
            user_profile: $profile
            content: $content
            cover_image: $cover_image
            attachments: $attachments
          }
        }
      ) {
        bulletinBoardPost {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newEntry);
};

export async function updateBoardEntry(id, data) {
  const mutation = gql`
    mutation UpdateBulletinBoardPost(
      $title: String!
      $zipcode: ID!
      $category: ID!
      $band: ID
      $profile: ID!
      $content: String!
      $cover_image: ID
      $attachments: [ID]
    ) {
      updateBulletinBoardPost(
        input: {
          where: { id: ${id} }
          data: {
            title: $title
            zipcode: $zipcode
            category: $category
            band: $band
            user_profile: $profile
            content: $content
            cover_image: $cover_image
            attachments: $attachments
          }
        }
      )
      {
        bulletinBoardPost {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export const useBoardCategories = () => {
  const query = `
    {
      bulletinBoardCategories(
        sort: "name:asc"
      ) {
        id
        name
      }
    }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    categories: data?.bulletinBoardCategories,
    isLoading: !error && !data,
    isError: error,
  };
};

export function usePlaylists(typeFilter = [], categoryFilter = []) {
  const typeWhere = !typeFilter ? "" : ``;
  const categoryWhere = !categoryFilter ? "" : ``;

  const query = `
  {
    playlists (
      where: {
        type_in: [${typeFilter}]
        category_in: [${categoryFilter}]
        songs_null: false
        songs: { band_null: false }
      }
    )
    {
      id
      slug
      title
      description
      image {
        formats
      }
      genre {
        title
      }
      songs {
        id
        title
        soundcloud_id
        band {
          slug
          name
          zipcode {
            label
          }
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    playlists: data?.playlists,
    isLoading: !error && !data,
    isError: error,
  };
}

/*
export function useSearchPlaylists(searchConfig) {
  const nameFilter = searchConfig.query
    ? `title_contains: "${searchConfig.query}"`
    : "";
  const genreFilter = searchConfig.genre
    ? `genre: ${searchConfig.genre.id}`
    : "";
  const regionFilter = searchConfig.region
    ? `region: { id: ${searchConfig.region.id} }`
    : "";

  const query = `
  {
    playlists (
      where: {
        type_ne: "usergenerated"
        songs_null: false
        songs: { band_null: false }
        ${nameFilter}
        ${genreFilter}
        ${regionFilter}
      }
    )
    {
      id
      slug
      title
      description
      image {
        formats
      }
      genre {
        title
      }
      songs {
        id
        title
        soundcloud_id
        band {
          slug
          name
          zipcode {
            label
          }
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    playlists: data?.playlists,
    isLoading: !error && !data,
    isError: error,
  };
}
*/

export function useSearchPlaylists(filterConfig, start, limit) {
  const queryFilter = !filterConfig.query ? "" : `&query=${filterConfig.query}`;
  const regionFilter = !filterConfig.region
    ? ""
    : `&regionId=${filterConfig.region?.id ?? filterConfig.region}`;
  const genreFilter = !filterConfig.genre
    ? ""
    : `&genreId=${filterConfig.genre?.id ?? filterConfig.genre}`;
  const languageFilter = !filterConfig.language
    ? ""
    : `&language=${filterConfig.language}`;
  const surroundFilter =
    !filterConfig.radius || !filterConfig.zipcode
      ? ""
      : `&zipcode=${filterConfig.zipcode.zipcode}&radius=${filterConfig.radius}`;
  const genderFilter =
    !filterConfig.gender || filterConfig.gender === "not_specified"
      ? ""
      : `&gender=${filterConfig.gender}`;
  const minMemberFilter = !filterConfig.members_min
    ? ""
    : `&members_min=${filterConfig.members_min}`;
  const maxMemberFilter = !filterConfig.members_max
    ? ""
    : `&members_max=${filterConfig.members_max}`;
  const minAgeFilter = !filterConfig.age_min
    ? ""
    : `&age_min=${filterConfig.age_min}`;
  const maxAgeFilter = !filterConfig.age_max
    ? ""
    : `&age_max=${filterConfig.age_max}`;

  const sort = !filterConfig._sort ? "" : `&_sort=${filterConfig._sort}`;

  const allFilters = `${queryFilter}${regionFilter}${genreFilter}${languageFilter}${genderFilter}${minMemberFilter}${maxMemberFilter}${minAgeFilter}${maxAgeFilter}${surroundFilter}${sort}`;

  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(
    `/api/playlists?_start=${start}&_limit=${limit}&${allFilters}`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    playlists: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
}

export function useTopPlaylists(type) {
  const query = `
  {
    playlists
    (
      where: {
        type: "${type}"
        songs_null: false
        songs: { band_null: false }
      }
    )
    {
      id
      slug
      title
      image {
        formats
      }
      genre {
        title
      }
      songs {
        id
        title
        soundcloud_id
        band {
          id
          name
          slug
          photo {
            formats
          }
        }
      }
    }
  }`;

  const { data, error } = useSWR(query, fetcher);

  return {
    playlists: data?.playlists,
    isLoading: !error && !data,
    isError: error,
  };
}

export const createProfile = async (user) => {
  const mutation = gql`
    mutation AddUserProfile($email: String, $user: ID!) {
      createUserProfile(
        input: { data: { name: $email, email: $email, user: $user } }
      ) {
        userProfile {
          id
        }
      }
    }
  `;

  const { data, error } = await fetcher(mutation, {
    user: user.id,
    email: user.email,
  });

  return {
    profile: data?.userProfile,
    isLoading: !error && !data,
    isError: error,
  };
};

export async function getProfile(profileId) {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/user-profiles/${profileId}`,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );
  return response.data;
}

export async function getProfileByEMail(email) {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/user-profiles?_where[email]=${email}&_sort=id`,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );
  return response.data;
}

export function useProfileRest(profileId) {
  const { data, error } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/user-profiles/${profileId}`,
    restFetcher,
  );

  return {
    profile: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useProfile(user) {
  const query = `
  {
    userProfile(id: ${user?.profile})
    {
      id
      name
      age
      gender
      email
      consent_newsletter
      consent_messages
      email_frequency
      bands {
        id
        name
        last_updated_by {
          profile {
            name
          }
        }
      }
    }
  }`;

  const { data, error } = useSWR(user?.profile ? query : null, fetcher);

  return {
    profile: data?.userProfile,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function updateUserForDeletion(id, data) {
  const mutation = gql`
    mutation UpdateUser(
      $blocked: Boolean
      $expires_at: DateTime
    ) {
      updateUser(
        input: {
          where: { id: ${id} }
          data: {
            blocked: $blocked
            expires_at: $expires_at
          }
        }
      )
      {
        user {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export function useProfileById(id) {
  const query = `
  {
    userProfile(id: ${id})
    {
      id
      name
      age
      gender
      email
      consent_newsletter
      consent_messages
      email_frequency
      user {
        id
        username
        email
      }
    }
  }`;

  const { data, error, mutate } = useSWR(id ? query : null, fetcher);

  return {
    profile: data?.userProfile,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export async function updateProfileNotifications(id, data) {
  const mutation = gql`
    mutation UpdateUserProfile(
      $email_frequency: String
      $consent_messages: DateTime
      $consent_newsletter: DateTime
    ) {
      updateUserProfile(
        input: {
          where: { id: ${id} }
          data: {
            email_frequency: $email_frequency
            consent_messages: $consent_messages
            consent_newsletter: $consent_newsletter
          }
        }
      )
      {
        userProfile {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updateProfileDetails(id, data) {
  const mutation = gql`
    mutation UpdateUserProfile(
      $name: String
      $email: String
      $gender: String
    ) {
      updateUserProfile(
        input: {
          where: { id: ${id} }
          data: {
            name: $name
            email: $email
            gender: $gender
          }
        }
      )
      {
        userProfile {
          id
          user {
            id
          }
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updateProfileUser(id, data) {
  const mutation = gql`
    mutation UpdateUserProfile(
      $user: ID!
    ) {
      updateUserProfile(
        input: {
          where: { id: ${id} }
          data: {
            user: $user
          }
        }
      )
      {
        userProfile {
          id
          user {
            id
          }
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updateUserDetails(id, data) {
  const mutation = gql`
    mutation UpdateUserProfile(
      $email: String
    ) {
      updateUser(
        input: {
          where: { id: ${id} }
          data: {
            username: $email
            email: $email
          }
        }
      )
      {
        user {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export async function updatePassword(data) {
  const result = await axios.post(
    `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}/password`,
    data,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export async function forgotPassword(email) {
  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/auth/forgot-password`,
    { email: email },
  );
}

export async function resetPassword(password, passwordConfirmation, code) {
  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/auth/reset-password`,
    {
      code: code,
      password: password,
      passwordConfirmation: passwordConfirmation,
    },
  );
}

export async function sendContact(data) {
  const result = await axios.post(
    `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}/contact`,
    data,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export function useFooter() {
  const query = `
  {
    footer {
      cta_title
      cta_description
      cta_register_label
      cta_about_label
      logos {
        image {
          formats
        }
        link
      }
    }
  }`;

  const { data, error } = useSWRImmutable(query, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    footer: data?.footer,
    isLoading: !error && !data,
    isError: error,
  };
}

/**
 *
 * @param {String} path Path must start with /
 * @returns
 */
export const usePage = (path) => {
  const { data, error } = useSWR(
    path
      ? `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}${path}`
      : null,
    pageFetcher,
  );

  return {
    page: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCreateMusicBase = (zipcode) => {
  const query = `
    {
      regions (
        where: {
          zipcodes: {
            zipcode: "${zipcode}"
          }
          locations: {
            base: true
          }
        }
        limit: 1
      ){
        id
        name
        locations {
          id
          name
          address
          website
          email
          phone
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    region: data?.regions,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const createChatRoom = async (newRoom) => {
  const mutation = gql`
    mutation AddChatRoom(
      $title: String!
      $members: [ID]
      $bands: [ID]
      $uuid: String
    ) {
      createChatroom(
        input: {
          data: { title: $title, members: $members, bands: $bands, uuid: $uuid }
        }
      ) {
        chatroom {
          id
          uuid
          title
          members {
            id
            name
          }
          bands {
            photo {
              formats
            }
          }
          messages {
            message
            created_at
          }
        }
      }
    }
  `;

  return await fetcher(mutation, newRoom);
};

export async function createChatRoomLastSeen(newEntry) {
  const mutation = gql`
    mutation AddChatRoomLastSeen($chatroom: ID!, $user_profile: ID!) {
      createChatRoomLastSeen(
        input: { data: { chatroom: $chatroom, user_profile: $user_profile } }
      ) {
        chatRoomLastSeen {
          id
          updated_at
          user_profile {
            id
          }
        }
      }
    }
  `;

  return await fetcher(mutation, newEntry);
}

export async function updateChatRoomLastSeen(id, serial) {
  const mutation = gql`
    mutation UpdateChatRoomLastSeen(
      $serial: String!
     ) {
      updateChatRoomLastSeen(
        input: {
          where: { id: ${id} }
          data: {
            serial: $serial
          }
        }
      )
      {
        chatRoomLastSeen {
          id
          updated_at
          user_profile {
            id
          }
        }
      }
    }
  `;

  return await fetcher(mutation, { id, serial });
}

export function useUnreadMessages() {
  const swr = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/chatmessages/unread`,
    restFetcher,
    { refreshInterval: 90000 },
  );
  const { data, error, isLoading, mutate } = swr;

  return {
    count: data?.count,
    isLoading,
    isError: error,
    mutate: mutate,
  };
}

export async function updateChatRoomMembers(id, memberIds) {
  const mutation = gql`
    mutation UpdateChatRoom(
      $members: [ID]
    ) {
      updateChatroom(
        input: {
          where: { id: ${id} }
          data: {
            members: $members
          }
        }
      )
      {
        chatroom {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, memberIds);
}

export const useChatMessages = (room) => {
  const query = `
    {
      chatmessages (
        where: {
          chatroom: {
            id: "${room?.id}"
          }
        }
        sort: "created_at:desc"
      ){
        id
        message
        created_at
        author {
          id
          name
        }
        attachments {
          id
          name
          url
          formats
          mime
        }
      }
    }
  `;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    room?.id ? query : null,
    fetcher,
    { refreshInterval: 10000 },
  );

  return {
    messages: data?.chatmessages,
    isLoading,
    isError: error,
    mutate: mutate,
  };
};

export const createChatMessage = async (newMessage) => {
  const mutation = gql`
    mutation AddChatMessage(
      $author: ID!
      $chatroom: ID!
      $message: String!
      $attachments: [ID]
    ) {
      createChatmessage(
        input: {
          data: {
            author: $author
            chatroom: $chatroom
            message: $message
            attachments: $attachments
          }
        }
      ) {
        chatmessage {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newMessage);
};

export const useChatRooms = (profileId, administrative = false) => {
  const query = `
    {
      chatrooms(
        where: {
          members_in: ${profileId}
          uuid_null: false
          ${administrative ? "is_administrative: true" : ""}
        }
      ) {
        id
        uuid
        title
        is_administrative
        members {
          id
          name
        }
        bands {
          name
          photo {
            formats
          }
        }
        messages {
          message
          created_at
        }
        lastseen {
          id
          updated_at
          user_profile {
            id
          }
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    chatrooms: data?.chatrooms,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export async function getChatroom(uuid) {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/chatrooms?uuid=${uuid}`,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );
  return response.data;
}

export const uploadFile = async (file) => {
  const data = new FormData();
  data.append("files", file);

  const result = await axios.post(
    `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
};

export const deleteFile = async (id) => {
  return restDeleteFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/upload/files/${id}`,
  );
};

export const getFile = async (id) => {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/upload/files/${id}`);
};

export const sendConfirmationEmail = (email) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_API_URL}/auth/send-email-confirmation`,
    {
      email,
    },
  );
};

export const confirmEmail = (code) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/auth/email-confirmation?confirmation=${code}`,
  );
};

export const useKnowledgebaseEntries = () => {
  const query = gql`
    {
      knowledgeBaseCategories(sort: "order:asc") {
        id
        title
        knowledge_base_entries {
          id
          title
          slug
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    knowledgeBaseEntries: data?.knowledgeBaseCategories,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const getKnowledgebaseCategories = (slug) => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/knowledge-base-categories`,
  );
};

export const getKnowledgebaseEntry = (slug) => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/knowledge-base-entries?slug=${slug}`,
  );
};

export const getKnowledgebaseEntries = () => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/knowledge-base-entries`,
  );
};

export const useKnowledgebaseEntry = (slug) => {
  const query = gql`
    {
      knowledgeBaseEntries(
        where: {slug: "${slug}"}
        limit: 1
      ) {
        id
        title
        content
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    knowledgeBaseEntry: data?.knowledgeBaseEntries[0],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const useStudiengangs = () => {
  const query = gql`
    {
      studiengangs(sort: "name:asc") {
        id
        name
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    studiengangs: data?.studiengangs,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const useUniversityDegrees = () => {
  const query = gql`
    {
      universityDegrees(sort: "name:asc") {
        id
        name
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    universityDegrees: data?.universityDegrees,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const getPlayerPlaylist = async (type, id) => {
  return restFetcher(`/proxy/player/${type}/${id}`);
};

export const usePlayerPlaylist = (type, id, cache = false) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    type !== null && id !== null
      ? `/proxy/player/${type}/${id}?cache=${cache}`
      : null,
    restFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  return {
    playlist: data,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
};

export const useAdminMessage = () => {
  const query = `
    {
      configuration {
        dashboard_message
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    message: data?.configuration?.dashboard_message,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const useAdminSharedPassword = () => {
  const query = `
    {
      configuration {
        admin_shared_password
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    shared_password: data?.configuration?.admin_shared_password,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export async function createFilterConfig(newConfig) {
  const mutation = gql`
    mutation AddFilterConfig($config: String!, $title: String) {
      createFilterConfig(input: { data: { config: $config, title: $title } }) {
        filterConfig {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, newConfig);
}

export async function createPlaylist(playlist) {
  const mutation = gql`
    mutation AddPlaylist(
      $title: String!
      $name: String
      $type: ENUM_PLAYLIST_TYPE
      $songs: [ID]!
    ) {
      createPlaylist(
        input: {
          data: { title: $title, name: $name, type: $type, songs: $songs }
        }
      ) {
        playlist {
          id
          slug
        }
      }
    }
  `;

  return await fetcher(mutation, playlist);
}

export const getFilterConfig = (id) => {
  return restFetcher(`${process.env.NEXT_PUBLIC_API_URL}/filter-configs/${id}`);
};

export async function updateFilterConfig(id, data) {
  const mutation = gql`
    mutation UpdateFilterConfig(
      $title: String!
    ) {
      updateFilterConfig(
        input: {
          where: { id: ${id} }
          data: {
            title: $title
          }
        }
      )
      {
        filterConfig {
          id
        }
      }
    }
  `;

  return await fetcher(mutation, data);
}

export const getPermissions = async (user) => {
  return restFetcher(
    `${process.env.NEXT_PUBLIC_API_URL}/users-permissions/roles/${user?.role?.id}`,
  );
};

export const useRole = (user) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/users-permissions/roles/${user?.role?.id}`,
    restFetcher,
    { revalidateOnFocus: false },
  );

  return {
    role: data?.role,
    isLoading,
    isValidating,
    isError: error,
    mutate: mutate,
  };
};

export async function sendNewsletter(newsletter) {
  const result = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
    }/newsletter/send`,
    newsletter,
    {
      headers: {
        authorization: `Bearer ${Cookie.get("token")}`,
      },
    },
  );

  return result.data;
}

export const useFeed = (index, count) => {
  const query = `
    {
      feedEvents (
        start: ${index},
        limit: ${count},
        sort: "created_at:desc"
      ){
        id
        type
        object_id
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    events: data?.feedEvents,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const useSocialMediaPosts = () => {
  const query = `
    {
      socialMediaPosts(
        sort: "created_at:desc"
      ) {
        id
        title
        type
        text
        media {
        url
        }
      }
    }
  `;

  const { data, error, mutate } = useSWR(query, fetcher);

  return {
    posts: data?.socialMediaPosts,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
