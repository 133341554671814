import { atom, selector } from "recoil";

const playerState = atom({
  key: "playerState",
  default: null,
});

export const playerProgressRelativeState = selector({
  key: "playerpProgressRelativeState",
  get: ({ get }) => {
    const state = get(playerState);
    return state?.progress ?? 0;
  },
});

export const playerProgressAbsoluteState = selector({
  key: "playerProgressAbsoluteState",
  get: ({ get }) => {
    const state = get(playerState);
    return state?.seconds ?? 0;
  },
});

export const playerTrackDurationState = selector({
  key: "playerTrackDurationState",
  get: ({ get }) => {
    const state = get(playerState);
    return state?.duration ?? 0;
  },
});

const actualTrackState = atom({
  key: "actualTrack",
  default: null,
});

const nextTrackState = atom({
  key: "nextTrackState",
  default: null,
});

const playerPlaylistState = atom({
  key: "playerPlaylist",
  default: null,
});

const playerIsPlayingState = atom({
  key: "playerIsPlaying",
  default: false,
});

const playerCommandState = atom({
  key: "playerCommand",
  default: null,
});

const preferredViewModeState = atom({
  key: "preferredViewMode",
  default: "list",
});

const contentFullscreenState = atom({
  key: "contentFullscreen",
  default: false,
});

const popboardViewState = atom({
  key: "popboardView",
  default: false,
});

const newsletterViewState = atom({
  key: "newsletterView",
  default: "search",
});

const newsletterFoundBandsState = atom({
  key: "newsletterFoundBands",
  default: [],
});

const newsletterSelectedBandsState = selector({
  key: "newsletterSelectedBands",
  get: ({ get }) => {
    const bands = get(newsletterFoundBandsState);
    const selectedBands = bands.filter((b) => b.selected);
    return selectedBands;
  },
});

const searchResultCountState = atom({
  key: "searchResultCount",
  default: {
    songs: 0,
    bands: 0,
    playlists: 0,
  },
});

const searchResultPagesState = atom({
  key: "searchResultPages",
  default: {
    songs: [],
    bands: [],
    playlists: [],
  },
});

const searchResultSongsState = atom({
  key: "searchResultSongs",
  default: [],
});

const searchViewState = atom({
  key: "searchView",
  default: 0,
});

const facebookAccessTokenState = atom({
  key: "facebookAccessToken",
  default: 0,
});

export {
  actualTrackState,
  contentFullscreenState,
  facebookAccessTokenState,
  newsletterFoundBandsState,
  newsletterSelectedBandsState,
  newsletterViewState,
  nextTrackState,
  playerCommandState,
  playerIsPlayingState,
  playerPlaylistState,
  playerState,
  popboardViewState,
  preferredViewModeState,
  searchResultCountState,
  searchResultPagesState,
  searchResultSongsState,
  searchViewState,
};
